import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { MiddleText } from 'UI';
import {
  Wrapper, Logo, LinkButton, Title, EmailButton, SocialLinks,
} from './Links.styled';
import { ILink, ISocialLink } from './type';

const query = graphql`
  query {
    page: strapiInfo {
      footer {
        social {
          icon {
            ...MediaSVGFragment
          }
          id
          link
          text
          title
        }
      }
    }
    feedMediumBlog {
      link
      title
    }
  }
`;

interface IFooter {
  legal: string;
  social: ISocialLink[];
  links: ILink[];
}

interface IFooterQuery {
  page: {
    footer: IFooter;
  };
  feedMediumBlog: {
    link: string;
    title: string;
  }
}

interface ILinksPage {
  title: string;
  links: ILink[];
}

const Links: FC<ILinksPage> = ({ title, links }) => (
  <StaticQuery
    query={query}
    render={(data: IFooterQuery) => {
      const { feedMediumBlog, page } = data;
      const footerData = page.footer;
      const { social } = footerData;

      return (
        <Wrapper>
          <Logo />
          <Title>
            {title}
          </Title>
          {links.map(({ text, link }) => (
            <LinkButton key={`links-${link}`} href={link}>
              <MiddleText>
                {text}
              </MiddleText>
            </LinkButton>
          ))}
          <LinkButton href={feedMediumBlog.link}>
            <MiddleText>
              {feedMediumBlog.title}
            </MiddleText>
          </LinkButton>
          <EmailButton href="mailto:info@wayray.com">
            info@wayray.com
          </EmailButton>
          <SocialLinks links={social} />
        </Wrapper>
      );
    }}
  />
);

export default Links;
