import styled from '@emotion/styled';
import Markdown from 'markdown-to-jsx';

import { ArrowLinkStyles } from 'UI/ArrowLink/ArrowLink';
import { ButtonLink, MiddleText } from 'UI';
import { FullLogo } from 'UI/Icons';
import { MIN_2K, MIN_TABLET_WIDTH } from 'constants/sizes';

import UISocialLinks from '../../Footer/SocialLinks/SocialLinks';

export const Logo = styled(FullLogo)`
  width: 12em;
  margin-bottom: 2em;
`;

export const Wrapper = styled.div`
  padding: 1.5em;
  display: grid;
  grid-auto-flow: row;
  gap: 1em;
  margin: 0 auto;

  @media (min-width: ${MIN_TABLET_WIDTH}px) {
    padding: 3em;
  }
`;

export const Title = styled(Markdown)`
  margin-bottom: 1.5em;

  a {
    color: var(--red);
    font-weight: 500;
  }

  h2 {
    padding-top: 0.2em;
  }
`;

const UIButton = styled(ButtonLink)`
  height: auto;
  min-height: 5em;
  padding: 1.5em;
  text-align: center;

  letter-spacing: 0.015em;
  line-height: 1.2;

  &:hover {
    letter-spacing: -0.015em;
  }

  @media (min-width: ${MIN_TABLET_WIDTH}px) {
    font-size: 16px;
  }

  @media (min-width: ${MIN_2K}px) {
    font-size: 1.2em;
  }
`;

export const LinkButton = styled.a`
  background: none;
  color: var(--black);
  justify-content: start;
  align-items: start;
  padding: 1em 0 0;
  border-top: 1px solid var(--gray-2);
  min-height: 4.5em;

  :hover {
    background: none;
    color: var(--black);
  }

  > ${MiddleText} {
    ${ArrowLinkStyles}
  }
`;

export const EmailButton = styled(UIButton)`
  margin: 1em 0 2em;
  font-weight: 500;
`;

export const SocialLinks = styled(UISocialLinks)`
  max-width: 45em;
  color: var(--gray);
  font-weight: 500;
`;
