import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IPage, IPageArticle } from 'interfaces/page';

import Head from 'components/Layout/Head/Head';
import Links from 'components/blocks/LinksPage/Links';
import { ILink, IMarkdownText } from 'interfaces/commonComponents';

const query = graphql`
  query {
    page: strapiLinksPage {
      meta {
        ...StrapiMetaFragment
      }
      title {
        text
      }
      links {
        text
        link
      }
    }
  }
`;

interface ILinksPage extends IPage {
  data: IPageArticle;
  title: IMarkdownText;
  links: ILink[];
}

interface IQueryLinks {
  page: ILinksPage;
}

const LinksPage: FC = () => (
  <StaticQuery
    query={query}
    render={(data: IQueryLinks) => {
      const siteInfo = data.page.meta;
      const { title, links } = data.page;

      return (
        <>
          <Head
            title={siteInfo.title}
            description={siteInfo.description}
            preview={siteInfo.preview}
          />
          <Links title={title.text} links={links} />
        </>
      );
    }}
  />
);

export default LinksPage;
